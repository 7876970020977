import { FC, useEffect, useRef, useState } from 'react';
import { Button, Col, Flex, Image, message, Row, Tooltip, Typography } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import SearchIcon from '../assets/images/normal_search_icon.svg';

import { COLOR_333, COLOR_WHITE, FONT_SIZES, FONT_WEIGHTS, PADDING_SIZES } from '../constant';
import MainLayout from '../layout';
import SideBar from '../components/SideBar';
import TableComponent from '../components/TableComponent';
import { membershipTableColumns } from '../components/TableComponent/tableColumns';
import Search, { SearchProps } from 'antd/es/input/Search';
import { UserListManagement, UserListManagementResult, UserProperty } from '../types';
import { dateFormatCustomize, dateFormatYearMonthDay, exportToExcel } from '../utils';
import { getTotalUserManagementInfo, getUserManagementInfo } from '../api/userManagement';
import Zippi from '../assets/images/zippy.png';

interface IProps {
}

interface TotalMember {
  total: number,
  totalFreeLite: number,
  totalSubscribed: number
}

export enum PLAN_TYPE {
  ONE_TRANSACTION = 'com.zippy.onetimereport',
  SUB_MONTHLY = 'com.zippy.sub1month',
  SUB_YEARLY = 'com.zippy.sub1year',
  FREE_TRIAL = 'com.zippy.freetrial',
}


const Membership: FC<IProps> = () => {
  const [tableData, setTableData] = useState<UserProperty[]>([]);
  const [totalMember, setTotalMember] = useState<TotalMember>();
  const [email, setEmail] = useState<string>('');
  const [messageApi, contextHolder] = message.useMessage();
  const searchRef = useRef(null);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    fetchData(skip, limit);
  }, [email]);

  useEffect(() => {
    getTotalUserManagementInfo().then(res => {
      const totalMember = {
        total: res.totalUser,
        totalSubscribed: res.totalSubscribed,
        totalFreeLite: res.totalFreeLite,
      };
      setTotalMember(totalMember);
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  const fetchData = async (skip: number, limit: number) => {
    try {
      skip = skip -1;
      const data: UserListManagement = await getUserManagementInfo(skip, limit, email);
      setTotal(data.total);
      setTableData(data.memberList.map((item, index) =>
        ({
          ...item,
          key: item.uid,
          no: data.memberList.length - index ,
          registeredDate: dateFormatYearMonthDay(item?.registeredDate),
          subscribed: dateFormatYearMonthDay(item?.subscribed),
          subscriptionExpiryDate: dateFormatYearMonthDay(item?.subscriptionExpiryDate),
          dateOfTransaction: dateFormatYearMonthDay(item?.dateOfTransaction),
          lite: item?.lite === PLAN_TYPE.FREE_TRIAL ? '라이트' : '프로'
        })));
    } catch (error) {
      if(error == 'Error: email must be an email') {
        messageApi.error(`이메일 형식을 확인해주세요`);
      }else {
        messageApi.error(`${error} `);
      }
    }
  };


  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    if (value.trim() !== '') {
      setEmail(value);
    } else {
      setEmail('');
    }
  };

  return (
    <MainLayout>
      {contextHolder}
      <Row style={{ minHeight: '100vh' }}>
        <SideBar />
        <Col span={20} style={{ backgroundColor: COLOR_WHITE, padding: PADDING_SIZES.lg }}>
          <Flex vertical gap={20}>
            <Typography.Text style={{ color: COLOR_333, fontSize: FONT_SIZES.huge, fontWeight: FONT_WEIGHTS.semibold }}>
              회원
            </Typography.Text>
            <Flex justify='space-between' align='center'>
              <Flex gap={10}>
                <Typography.Text style={{ color: COLOR_333, fontSize: FONT_SIZES.lg }}>
                  전체회원: {totalMember?.total}명 (라이트: {totalMember?.totalFreeLite}명 | 프로 {totalMember?.totalSubscribed}명)
                </Typography.Text>
                <Tooltip title='Export to Excel'>
                  <Button
                    type='default'
                    shape='circle'
                    icon={<FileExcelOutlined />}
                    onClick={() => exportToExcel(total, 'Membership Management')}
                  />
                </Tooltip>
              </Flex>
              <div className='wrap-btn-search'>
                <Search
                  ref={searchRef}
                  placeholder='아이디 평문 입력'
                  allowClear
                  onSearch={onSearch}
                  style={{ width: '300px' }}
                  size='large'
                  className='search-member'
                />
                <Image src={SearchIcon} alt='SearchIcon' preview={false}/>
              </div>

            </Flex>
            <TableComponent tableColumns={membershipTableColumns} tableData={tableData} noDataContent='' total={total} page={fetchData} />
          </Flex>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Membership;
