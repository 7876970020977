import { NavigateFunction } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import { UserListManagement, UserProperty } from '../types';
import moment from 'moment';
import { getUserManagementInfo } from '../api/userManagement';
import { PLAN_TYPE } from '../pages/Membership';

export const handleNavigate = (navigate: NavigateFunction, path: string | undefined) => {
  navigate(path || '/');
};

export const getFirstPathURL = (path: string) => {
  return `/${path.split('/')[1]}`;
};

export const exportToExcel = async (total: number, fileName = 'table-data') => {
  const users : UserListManagement = await getUserManagementInfo(0, total);
  const data = users.memberList.map((item, index) => {
    return {
      no: users.memberList.length - index,
      uid: item.uid,
      registeredDate: dateFormatYearMonthDay(item?.registeredDate),
      lite: item?.lite === PLAN_TYPE.FREE_TRIAL ? '라이트' : '프로',
      subscribed: dateFormatYearMonthDay(item?.subscribed),
      subscriptionExpiryDate: dateFormatYearMonthDay(item?.subscriptionExpiryDate),
      oneTimeTransactionCount: item.oneTimeTransactionCount.toString(),
      dateOfTransaction: dateFormatYearMonthDay(item?.dateOfTransaction),
    };
  });

  const duplicateItem = { ...data[0] };
  data.unshift(duplicateItem);
  const ws = XLSX.utils.json_to_sheet(data);

  // Merge cells A1 and A2
  ws['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } }, // Merge A1:A2
  ];
  ws['A1'].v = '  ';

  // Merge cells B1 and B2
  ws['!merges'].push(
    { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } }, // Merge B1:B2
  );
  ws['B1'].v = '아이디';

  // Merge cells C1 to F1
  ws['!merges'].push({ s: { r: 0, c: 2 }, e: { r: 0, c: 5 } });
  ws['C1'].v = '멤버십';

  ws['C2'].v = '가입일';
  ws['D2'].v = '유형';
  ws['E2'].v = '결제일';
  ws['F2'].v = '만료일';

  // Merge cells G1 to H1
  ws['!merges'].push({ s: { r: 0, c: 6 }, e: { r: 0, c: 7 } });
  ws['G1'].v = '지기리포트';

  ws['G2'].v = '누적 결제 수';
  ws['H2'].v = '최근결제일';

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // ANCHOR - Buffer
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  // ANCHOR - Create a Blob
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  // ANCHOR - Save the file
  saveAs(blob, `${fileName}.xlsx`);
};

export const dateFormatYearMonthDay = (date: string) => {
  return date ? moment(date).format('YY-MM-DD') : '-';
};

export const dateFormatCustomize = (date: string, formatType: string) => {
  return moment(date).format(formatType);
};

export const getTagName = (el: ChildNode) => ('tagName' in el ? String(el.tagName || '').toLowerCase() : '');
