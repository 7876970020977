import { FC, useEffect, useState } from 'react';
import { Button, Flex, Form, Input, Typography } from 'antd';

import { PADDING_SIZES } from '../../constant';
import { Announcement, ConnectionPostFieldType } from '../../types';
import PostListModal from "../../components/ModalComponent/PostListModal";
import useAnnouncementStore from '../../store/AnnouncementSelectedStore';

interface IProps {}

const ConnectionPostForm: FC<IProps> = () => {
  const [submitBtn, setSubmittable] = useState<boolean>(false);
  const [openPostListModal, setOpenPostListModal] = useState<boolean>(false);
  const selectedAnnouncement = useAnnouncementStore(state => state.selectedAnnouncement);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const validateMessages = {
    required: '',
  };

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  useEffect(() => {
    if (selectedAnnouncement) {
      form.setFieldsValue({ connectionPost: selectedAnnouncement.title });
    } else {
      form.setFieldsValue({ connectionPost: '' });
    }
  }, [selectedAnnouncement, form]);

  return (
    <Form form={form} name='connectionPostValidateOnly' autoComplete='off' validateMessages={validateMessages}>
      <Flex align='center' gap={20}>
        <Typography.Text style={{ paddingBottom: PADDING_SIZES.lg }}>연결 게시글:</Typography.Text>
        <Form.Item<ConnectionPostFieldType> name='connectionPost' rules={[{ required: true }]} style={{ flexGrow: 1 }}>
          <Input size='large' disabled={true} placeholder='목록 버튼을 눌러 게시글을 선택해주세요'/>
        </Form.Item>
        <Form.Item>
          <Button type='primary' htmlType='submit' size='large' onClick={() => {setOpenPostListModal(true)}}>
            게시글 목록
          </Button>
          <PostListModal isModalOpen={openPostListModal} setIsModalOpen={setOpenPostListModal}/>
        </Form.Item>
      </Flex>
    </Form>
  );
};

export default ConnectionPostForm;
