import React from 'react';
import { Button, Modal } from 'antd';
import { PushAnnouncement } from '../../types';
import { dateFormatCustomize, handleNavigate } from '../../utils';
import { useNavigate } from 'react-router-dom';
import  './detailPushMessage.scss';

interface CustomModalProps {
  visible: boolean;
  onClose: () => void;
  record: PushAnnouncement | null;
}

const DetailPushAnnouncementModal: React.FC<CustomModalProps> = ({ visible, onClose, record }) => {
  const navigate = useNavigate();

  return (
    <Modal
      title='푸시 발송 내역 상세'
      open={visible}
      onCancel={onClose}
      footer={<Button type='primary' htmlType='submit' onClick={onClose}>닫기</Button>}
      className='modal-push-announcement-container'
    >
      <div className='modal-body'>
        <div className='push-announcement-title'>
          <div className='title'>{record?.title}</div>
          <div className='detail'>
            <div className='left'>발송대상: {record?.group === 'all' ? '전체회원' : record?.group === 'lite' ? '라이트' : '프로'}</div>
            <div className='right'>{dateFormatCustomize(`${record?.modalDeliveryTime}`, 'YY-MM-DD HH:mm')}</div>
          </div>
        </div>
        <div className='push-announcement-content'>
          {record?.content}
        </div>
        <div className='push-announcement-linkedPost'>
          <div className='left'>연결 게시물: {record?.linkedPost}</div>
          <div className='btn-redirect'>
            <Button
              htmlType='submit'
              disabled={!record?.announcementId}
              onClick={() => {
                handleNavigate(navigate, `/notice-event-detail?${record?.announcementId ? `no=${record?.announcementId}` : ''}`);
              }}>
              이동
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DetailPushAnnouncementModal;
