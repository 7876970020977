import { ColumnsType } from 'antd/es/table';
import { IEventNoticeTableData, IMemberShipTableData, IShippingDetailTableData } from '../../types';

export const membershipTableColumns: ColumnsType<
  IMemberShipTableData | IEventNoticeTableData | IShippingDetailTableData
> = [
  {
    title: '',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: '5%',
  },
  {
    title: '아이디',
    dataIndex: 'uid',
    key: 'id',
    align: 'center',
    width: '29%',
  },
  {
    title: '멤버십',
    children: [
      {
        title: '가입일',
        dataIndex: 'registeredDate',
        key: 'join-date',
        align: 'center',
        width: '11%',
      },
      {
        title: '유형',
        dataIndex: 'lite',
        key: 'membership',
        align: 'center',
        width: '11%',
      },
      {
        title: '결제일',
        dataIndex: 'subscribed',
        key: 'payment-date',
        align: 'center',
        width: '11%',
      },
      {
        title: '만료일',
        dataIndex: 'subscriptionExpiryDate',
        key: 'expiration-date',
        align: 'center',
        width: '11%',
      },
    ],
  },
  {
    title: '지기리포트',
    children: [
      {
        title: '누적 결제 수',
        dataIndex: 'oneTimeTransactionCount',
        key: 'payment-number',
        align: 'center',
        width: '11%',
      },
      {
        title: '최근결제일',
        dataIndex: 'dateOfTransaction',
        key: 'last-payment-date',
        align: 'center',
        width: '11%',
      },
    ],
  },
];

export const eventNoticeTableColumns: ColumnsType<
  IMemberShipTableData | IEventNoticeTableData | IShippingDetailTableData
> = [
  {
    title: '',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: '5%',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    width: '45%',
  },
  {
    title: '최초 작성일',
    dataIndex: 'createdAt',
    key: 'creation-date',
    align: 'center',
    width: '25%',
  },
  {
    title: '최종 수정일',
    dataIndex: 'updatedAt',
    key: 'lastModified-date',
    align: 'center',
    width: '25%',
  },
];

export const shippingDetailTableColumns: ColumnsType<
  IMemberShipTableData | IEventNoticeTableData | IShippingDetailTableData
> = [
  {
    title: '발송시간',
    dataIndex: 'deliveryTime',
    key: 'shipping-time',
    align: 'center',
    width: '15%',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    width: '35%',
  },
  {
    title: '연결게시물',
    dataIndex: 'linkedPost',
    key: 'linked-post',
    align: 'center',
    width: '35%',
  },
  {
    title: '발송대상',
    dataIndex: 'plan',
    key: 'shipping-destination',
    align: 'center',
    width: '15%',
  },
];
