import { del, get, post, postFormData, put } from './index';
import { PushAnnouncement, PushAnnouncementResult } from '../types';

export async function createPushAnnouncement(formData: FormData) {
  let url = '/admin/push-announcement';
  return await postFormData(url, formData);
}

export async function getPushAnnouncement(page: number, limit: number) {
  return await get<PushAnnouncementResult>(`/admin/push-announcement?page=${page}&pageSize=${Number(limit)}`);
}

export async function getPushAnnouncementById(id?: string) {
  return await get<PushAnnouncement>(`/admin/push-announcement/${id}`);
}

export async function editPushAnnouncement(id: string, title: string, content: string) {
  const data = { title, content };
  return await put(`/admin/push-announcement/${id}`, data);
}

export async function deletePushAnnouncement(id: string | null) {
  return await del(`/admin/push-announcement/${id}`);
}

export async function uploadImage(image: Blob | File) {
  const formData = new FormData();
  formData.append('image', image);
  return await postFormData<{ imageUrl: string }>(`/admin/push-announcement/upload-image`, formData);
}
