import { FC } from 'react';
import { Col, Divider, Flex, Image, Space } from 'antd';
import { ReactComponent as LogoutIcon } from '../../../src/assets/images/normal_u51.svg';
import Zippi from '../../assets/images/zippy.png';
import { PADDING_SIZES } from '../../constant';
import { LogoutOutlined, MailOutlined, UserOutlined, UnorderedListOutlined } from '@ant-design/icons';
import MenuItem from '../MenuItem';

interface IProps {}

const SideBar: FC<IProps> = () => {
  return (
    <Col span={4} style={{ padding: PADDING_SIZES.lg }}>
      <Flex vertical justify='space-between' style={{ height: '100%' }}>
        <Flex vertical align='center'>
          <Image src={Zippi} alt='Zippi' preview={false} />
          <Divider />
          <Space direction='vertical' align='start' size={30}>
            <MenuItem Icon={UserOutlined} title='회원' path='/membership' />
            <MenuItem Icon={UnorderedListOutlined} title='공지/이벤트' path='/notice-event' />
            <MenuItem Icon={MailOutlined} title='푸시메시지' path='/push-message' />
          </Space>
        </Flex>
        <Flex vertical align='center'>
          <Divider />
          <Space direction='vertical' align='start' size={30}>
            <MenuItem Icon={LogoutIcon} title='로그아웃' path='/login' />
          </Space>
        </Flex>
      </Flex>
    </Col>
  );
};

export default SideBar;
