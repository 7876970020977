import { Button, Flex, Form, Input } from 'antd';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css'; // Editor's Style
import { createAnnouncement, deleteAnnouncement, editAnnouncement, getAnnouncementById } from '../../api/announcement';
import { uploadImage } from '../../api/pushAnnouncement';
import ModalComponent from '../../components/ModalComponent';
import useNotificationStore, { MESSAGE_TYPE } from '../../store/notificationState';
import { EventNoticeFieldType } from '../../types';
import { getTagName, handleNavigate } from '../../utils';
import './index.scss';

export const MAX_CONTENT_LENGTH = 524288;

interface IProps {}

const EventNoticeForm: FC<IProps> = () => {
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isConfirmUpdate, setIsConfirmUpdate] = useState(false);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const setSuccess = useNotificationStore((state) => state.setSuccess);

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const { validateFields, setFieldsValue } = form;
  const editorRef = useRef<Editor | undefined>();
  const isReadyRef = useRef<boolean>(false);
  const [isUploadingImage, setUploadStatus] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, []);

  // Remove unused <br/> inside p tag wrapper includes img tag
  // (Fix to unxpected space in mobile)
  const getHTML = useCallback(() => {
    const html = editorRef.current?.getHTML() ?? '';
    const el = document.createElement('div');
    el.innerHTML = html;

    const imgs = el.querySelectorAll('img');
    imgs.forEach((img) => {
      const parent = img.parentNode;

      const lastChild = parent?.lastChild;

      if (lastChild && getTagName(lastChild) === 'br') {
        parent.removeChild(lastChild);
      }
    });

    return el.innerHTML;
  }, []);

  const fetchData = async () => {
    try {
      const data = await getAnnouncementById(searchParams.get('no') || '');
      form.setFieldValue('title', data.title);
      form.setFieldValue('content', data.content);
      if (data.content) editorRef.current?.setHTML(data.content);

      isReadyRef.current = true;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCreateAnnouncement = () => {
    createAnnouncement(form.getFieldValue('title'), getHTML() ?? '')
      .then((res) => {
        setSuccess(true, MESSAGE_TYPE.CREATE_ANNOUNCEMENT);
        handleNavigate(navigate, '/notice-event');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEditAnnouncement = () => {
    editAnnouncement(searchParams.get('no') || '', form.getFieldValue('title'), getHTML() ?? '')
      .then((res) => {
        setSuccess(true, MESSAGE_TYPE.EDIT_ANNOUNCEMENT);
        handleNavigate(navigate, '/notice-event');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const validateMessages = {
    required: '공백의 필드는 허용되지 않습니다!',
  };

  useEffect(() => {
    if (!values) return;

    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  useEffect(() => {
    if (isConfirm) {
      deleteAnnouncement(searchParams.get('no'))
        .then((res) => {
          setSuccess(true, MESSAGE_TYPE.REMOVE_ANNOUNCEMENT);
          handleNavigate(navigate, '/notice-event');
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isConfirm]);

  useEffect(() => {
    if (isConfirmUpdate) {
      handleEditAnnouncement();
    }
  }, [isConfirmUpdate]);

  useEffect(() => {
    const wrapper = document.querySelector('#editor') as HTMLElement;
    if (!wrapper) return;

    const editor = new Editor({
      el: wrapper,
      height: '330px',

      toolbarItems: [
        ['heading', 'bold', 'italic', 'strike'],
        ['ul', 'ol'],
        ['image', 'link'],
      ],
      initialValue: '',
      previewStyle: 'vertical',
      hideModeSwitch: true,
      usageStatistics: false,
      hooks: {
        async addImageBlobHook(blob, callback) {
          try {
            setUploadStatus(true);
            const res = await uploadImage(blob);

            const imageDescriptionInput = document.getElementById('toastuiAltTextInput') as HTMLInputElement;

            const altText = imageDescriptionInput?.value;

            callback(res.imageUrl, altText ?? '');
          } catch (e) {
            callback('');
          } finally {
            setUploadStatus(false);
          }
        },
      },

      events: {
        change: (params) => {
          if (params === 'wysiwyg') return;

          setFieldsValue({
            content: editor.getMarkdown(),
          });

          if (isReadyRef.current) validateFields(['content']);
        },
      },
    });

    editor.reset();

    editorRef.current = editor;
  }, [setFieldsValue, validateFields]);

  return (
    <>
      <Form
        form={form}
        name='validateOnly'
        layout='vertical'
        autoComplete='off'
        validateMessages={validateMessages}
        onFinish={() => handleCreateAnnouncement()}
      >
        <Form.Item<EventNoticeFieldType> name='title' label='제  목' rules={[{ required: true }]}>
          <Input size='large' showCount maxLength={255} />
        </Form.Item>
        <Form.Item<EventNoticeFieldType>
          className='content'
          name='content'
          label='내  용'
          rules={[
            { required: true },
            {
              max: MAX_CONTENT_LENGTH,
              message: `내용은 ${MAX_CONTENT_LENGTH}자를 초과할 수 없습니다.`,
            },
          ]}
        >
          <div id='editor' className={isUploadingImage ? 'uploading' : ''} />

          <span className='content__counter'>
            {values?.content?.length ?? 0} / {MAX_CONTENT_LENGTH}
          </span>
        </Form.Item>

        <Form.Item>
          {location.search ? (
            <Flex justify='end' gap={20}>
              <Button
                ghost
                type='primary'
                disabled={!submittable}
                size='large'
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  setIsConfirm(false);
                }}
              >
                삭제하기
              </Button>
              <Button
                type='primary'
                disabled={!submittable}
                size='large'
                onClick={() => {
                  setIsModalUpdateOpen(!isModalUpdateOpen);
                  setIsConfirmUpdate(false);
                }}
              >
                수정하기
              </Button>
            </Flex>
          ) : (
            <Flex justify='end'>
              <Button type='primary' htmlType='submit' size='large' disabled={!submittable}>
                등록하기
              </Button>
            </Flex>
          )}
        </Form.Item>
      </Form>
      <ModalComponent
        isModalOpen={isModalOpen}
        isConfirm={isConfirm}
        setIsConfirm={setIsConfirm}
        setIsModalOpen={setIsModalOpen}
      />
      <ModalComponent
        isModalOpen={isModalUpdateOpen}
        setIsModalOpen={setIsModalUpdateOpen}
        isConfirm={isConfirmUpdate}
        setIsConfirm={setIsConfirmUpdate}
        isModalConfirm={true}
      />
    </>
  );
};

export default EventNoticeForm;
