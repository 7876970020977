import { FC } from 'react';
import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { handleNavigate } from '../../utils';
import { deleteAnnouncement } from '../../api/announcement';
import useNotificationStore, { MESSAGE_TYPE } from '../../store/notificationState';
import  './modal-common.scss';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isConfirm: boolean;
  setIsConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  isModalConfirm?: boolean;
}

const ModalComponent: FC<IProps> = ({ isModalOpen, setIsModalOpen, isConfirm, setIsConfirm, isModalConfirm }) => {
  const navigate = useNavigate();
  const setSuccess = useNotificationStore((state) => state.setSuccess);

  const handleModal = (isConfirm: boolean) => {
    if (isConfirm) {
      setIsConfirm(true);
    } else {
      setIsConfirm(false);
    }
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      closable={false}
      width={300}
      className='modal-delete-announcement'
      okText='삭제'
      cancelText='취소'
      centered
      footer={[
        <button key="back" className={isModalConfirm? 'cancel-button' : 'cancel-button-remove'} onClick={() => handleModal(false)}>
          취소
        </button>,
        <button key="submit" className={isModalConfirm? 'ok-button' : 'ok-button-remove'} onClick={() => handleModal(true)}>
          {isModalConfirm? '확인' : '삭제'}
        </button>,
      ]}
    >
      <div className='content'>
        <span>{isModalConfirm? '수정된 내용을 반영하시겠습니까?' : '게시글을 삭제하시겠습니까?'}</span>
      </div>
    </Modal>
  );
};

export default ModalComponent;
