import { FC, useEffect, useState } from 'react';
import { Button, DatePicker, Flex, Form, Input, message, Radio, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import './pushMessage.scss';
import { PushMessageFieldType } from '../../types';
import useAnnouncementStore from '../../store/AnnouncementSelectedStore';
import { createPushAnnouncement } from '../../api/pushAnnouncement';
import useNotificationStore, { MESSAGE_TYPE } from '../../store/notificationState';
import { MAX_CONTENT_LENGTH } from '../EventNoticeForm';

interface IProps {}

const PushMessageForm: FC<IProps> = () => {
  const [submitBtn, setSubmittable] = useState<boolean>(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const selectedAnnouncement = useAnnouncementStore((state) => state.selectedAnnouncement);
  const resetSelectedAnnouncement = useAnnouncementStore((state) => state.resetSelectedAnnouncement);
  const [loadings, setLoadings] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const setSuccess = useNotificationStore((state) => state.setSuccess);

  const validateMessages = {
    required: '공백의 필드는 허용되지 않습니다!',
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const config = {
    rules: [
      {
        type: 'object' as const,
        required: values?.timeToShip === 'reserved' ? true : false,
        message: '',
      },
    ],
  };

  const submitData = () => {
    if (!selectedAnnouncement?.id) {
      setSuccess(true, MESSAGE_TYPE.FORGOT_ANNOUNCEMENT_ID);
      return;
    }
    const formData = new FormData();
    if (selectedAnnouncement?.id) {
      formData.append('announcementId', `${selectedAnnouncement?.id}`);
    }
    formData.append('title', form.getFieldValue('title'));
    formData.append('content', form.getFieldValue('detail'));
    formData.append(
      'deliveryTime',
      form.getFieldValue('timeToShip') === 'reserved' ? values?.datePicker?.format('YYYY-MM-DD HH:mm:ssZ') : new Date(),
    );
    formData.append('group', form.getFieldValue('placeToShip'));
    const fileList = form.getFieldValue('image');
    if (fileList && fileList.length > 0) {
      formData.append('image', fileList[0].originFileObj);
    }
    setLoadings(true);
    createPushAnnouncement(formData)
      .then(() => {
        // messageApi.success('')
        setSuccess(true, MESSAGE_TYPE.CREATE_PUSH_ANNOUNCEMENT);
        form.resetFields();
        form.setFieldValue('timeToShip', 'immediate');
        form.setFieldValue('placeToShip', 'all');
        resetSelectedAnnouncement();
        setSubmittable(false);
        setLoadings(false);
      })
      .catch((error) => {
        console.error(error);
        messageApi.error(error.toString());
        setLoadings(false);
      });
  };

  useEffect(() => {
    form.setFieldValue('timeToShip', 'immediate');
    form.setFieldValue('placeToShip', 'all');
  }, [form]);

  useEffect(() => {
    setTimeout(() => {
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false));
    });
  }, [form, values]);

  useEffect(() => {
    if (selectedAnnouncement) {
      form.setFieldsValue({ connectionPost: selectedAnnouncement.id });
    }
  }, [selectedAnnouncement]);

  useEffect(() => {
    return () => {
      resetSelectedAnnouncement();
    };
  }, []);

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        name='pushMessageValidateOnly'
        autoComplete='off'
        validateMessages={validateMessages}
        onFinish={() => submitData()}
      >
        <Form.Item<PushMessageFieldType> name='connectionPost' label='Post' rules={[{ required: false }]} hidden={true}>
          <Input size='large' />
        </Form.Item>
        <Form.Item
          name='image'
          label='이미지 업로드'
          valuePropName='fileList'
          getValueFromEvent={normFile}
          rules={[
            { required: false, message: '이미지를 업로드해주세요!' },
            ({ getFieldValue }) => ({
              validator(_, fileList) {
                if (fileList && fileList.length > 0) {
                  const file = fileList[0];
                  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                  const isLt15M = file.size / 1024 / 1024 < 15;

                  if (!isJpgOrPng) {
                    return Promise.reject('이미지 형식이 잘못되었습니다');
                  }
                  if (!isLt15M) {
                    return Promise.reject('최대 크기를 초과하였습니다');
                  }
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Upload
            name='image'
            accept='image/jpeg,image/png'
            action='/upload.do'
            listType='picture'
            beforeUpload={() => {
              return false;
            }}
            multiple={false}
            maxCount={1}
          >
            <Button>이미지 업로드</Button>
          </Upload>
        </Form.Item>
        <Form.Item<PushMessageFieldType> name='title' label='푸시 제목' rules={[{ required: true }]}>
          <Input size='large' showCount maxLength={255} />
        </Form.Item>
        <Form.Item<PushMessageFieldType> name='detail' label='푸시 세부정보' rules={[{ required: true }]}>
          <TextArea rows={8} showCount maxLength={MAX_CONTENT_LENGTH} />
        </Form.Item>
        <Flex align='center' gap={20}>
          <Form.Item<PushMessageFieldType> label='발송 시점' name='timeToShip' rules={[{ required: false }]}>
            <Radio.Group size='large'>
              <Radio.Button value='immediate'>즉시발송</Radio.Button>
              <Radio.Button value='reserved'>예약발송</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item<PushMessageFieldType> name='datePicker' {...config}>
            <DatePicker
              showTime
              format='YYYY-MM-DD HH:mm:ss'
              className={`${values?.timeToShip === 'reserved' ? 'show' : ''}`}
              size='large'
              disabledDate={(current) => current && current.valueOf() < Date.now() - 1000 * 60 * 60 * 24}
            />
          </Form.Item>
        </Flex>
        <Form.Item<PushMessageFieldType> label='발송 대상' name='placeToShip' rules={[{ required: false }]}>
          <Radio.Group size='large'>
            <Radio.Button value='all'>전체회원</Radio.Button>
            <Radio.Button value='subscribed'>프로</Radio.Button>
            <Radio.Button className='btn-group' value='lite'>
              라이트&nbsp;
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item style={{ borderTop: '1px solid #797979', paddingTop: 24, marginBottom: 0 }}>
          <Flex justify='end'>
            <Button type='primary' htmlType='submit' size='large' loading={loadings}>
              발송하기
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </>
  );
};

export default PushMessageForm;
