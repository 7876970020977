import { FC, useEffect, useRef, useState } from 'react';
import Table, { ColumnsType } from 'antd/es/table';
import { dateFormatCustomize } from '../utils';
import { PushAnnouncement } from '../types';
import { getPushAnnouncement } from '../api/pushAnnouncement';
import { shippingDetailTableColumns } from '../components/TableComponent/tableColumns';
import { getAnnouncement } from '../api/announcement';
import DetailPushAnnouncementModal from '../components/ModalComponent/detailPushAnnouncementModal';
import useNotificationStore, { MESSAGE_TYPE } from '../store/notificationState';
import { ConfigProvider, Empty } from 'antd';

interface IProps {}

const ListPushAnnouncement: FC<IProps> = () => {
  const [tableData, setTableData] = useState<PushAnnouncement[]>([]);
  const [total, setTotal] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<PushAnnouncement | null>(null);
  const { isSuccess } = useNotificationStore();
  const [page, setPage] = useState(1);

  const handleOpenModal = (record: PushAnnouncement) => {
    setSelectedRecord(record);
    setModalVisible(true);
  };
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    fetchData(page, 10);
  }, [isSuccess]);

  const fetchData = async (page: number, pageSize: number) => {
    try {
      const data = await getPushAnnouncement(page, pageSize);
      setTableData(data.data.map((item, index) =>
        ({
          ...item,
          key: item.id,
          no: index + 1,
          modalDeliveryTime: item.deliveryTime,
          deliveryTime: dateFormatCustomize(item.deliveryTime, 'YY-MM-DD HH:mm'),
          linkedPost: item.announcementTitle,
          plan: item?.group === 'all' ? '전체회원' : item?.group === 'lite' ? '라이트' : '프로'
        })));
      setTotal(data.total);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <div>
      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='일치하는 내역이 없습니다'/>}>
        <Table
          columns={shippingDetailTableColumns as ColumnsType<PushAnnouncement>}
          dataSource={tableData}
          bordered
          tableLayout='fixed'
          pagination={{ position: ['bottomCenter'], showSizeChanger: false, total: total, pageSize: 10, onChange: (page) => {fetchData(page, 10)} }}
          onRow={(record) => {
            return {
              onClick: (event) => {
                handleOpenModal(record);
              },
              style: { cursor: 'pointer' }
            };
          }}
        />
      </ConfigProvider>
      <DetailPushAnnouncementModal visible={modalVisible} onClose={handleCloseModal} record={selectedRecord}/>
    </div>


  );
};

export default ListPushAnnouncement;
