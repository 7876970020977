import { FC } from 'react';
import { Flex, Form, Input, message } from 'antd';

import { LoginFieldType, LoginResponseType } from '../../types';
import SubmitButton from '../../components/SubmitButton';
import { FORM_WIDTH } from '../../constant';
import { useNavigate } from 'react-router';
import { login } from '../../api/login';

interface IProps {
}

const LoginForm: FC<IProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();
  const error = () => {
    messageApi.error('아이디 또는 패스워드를 확인해주세요.');
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '이메일 형식을 확인해주세요.',
    },
  };

  const handleSubmit = async (values: any) => {
    const { email, password } = values;
    try {
      const response: LoginResponseType = await login(email, password);
      localStorage.setItem('accessToken', response.accessToken);
      navigate('/membership');
    } catch (e) {
      error();
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        name='validateOnly'
        layout='vertical'
        autoComplete='off'
        style={{ width: FORM_WIDTH }}
        validateMessages={validateMessages}
        onFinish={handleSubmit}
      >
        <Form.Item<LoginFieldType> name='email' rules={[{ required: false }]}>
          <Input size='large' placeholder='ID' />
        </Form.Item>
        <Form.Item<LoginFieldType> name='password' rules={[{ required: false }]}>
          <Input.Password size='large' placeholder='Password' />
        </Form.Item>
        <Form.Item>
          <Flex justify='end'>
            <SubmitButton form={form} isBlock={true}>
              로그인
            </SubmitButton>
          </Flex>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginForm;
