import { FC } from 'react';
import Table from 'antd/es/table';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirstPathURL, handleNavigate } from '../../utils';
import { ConfigProvider, Empty } from 'antd';

interface IProps<T> {
  tableColumns: T;
  tableData: T;
  noDataContent?: string;
  total?: number;
  page?: any;
}

const TableComponent: FC<IProps<any>> = ({ tableColumns, tableData, noDataContent= '일치하는 내역이 없습니다', total, page }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const updatePage = (pageNumber: number) => {
    page(pageNumber, 10)
  }

  return (
    <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={noDataContent}/>}>
      <Table
        columns={tableColumns}
        dataSource={tableData}
        bordered
        tableLayout='fixed'
        pagination={{ position: ['bottomCenter'], showSizeChanger: false, pageSize: 10, total: total, onChange: (pageNumber) => {updatePage(pageNumber)}}}
        // scroll={{ y: 'calc(100vh - 380px)' }}
        onRow={(record) => {
          return {
            onClick: (event) => {
              if (getFirstPathURL(location.pathname) === '/notice-event') {
                handleNavigate(navigate, `/notice-event-detail?no=${record.key}`);
              }
            },
            style: { cursor: getFirstPathURL(location.pathname) === '/notice-event' ? 'pointer' :'' }
          };
        }}
      />
    </ConfigProvider>
  );
};

export default TableComponent;
