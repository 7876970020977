import { FC, useEffect, useState } from 'react';
import { Button, ConfigProvider, Empty, Flex, Modal } from 'antd';
import { dateFormatCustomize } from '../../utils';
import { Announcement } from '../../types';
import Table, { ColumnsType } from 'antd/es/table';
import { eventNoticeTableColumns } from '../TableComponent/tableColumns';
import { getAnnouncement } from '../../api/announcement';
import useAnnouncementStore from '../../store/AnnouncementSelectedStore';

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PostListModal: FC<IProps> = ({ isModalOpen, setIsModalOpen }) => {
  const [tableData, setTableData] = useState<Announcement[]>([]);
  const [postList, setPostList] = useState<Announcement[]>([]);
  const [selectedRowsArray, setSelectedRowsArray] = useState<React.Key[]>([]);
  const setSelectedAnnouncement = useAnnouncementStore(state => state.setSelectedAnnouncement);
  const selectedAnnouncement = useAnnouncementStore(state => state.selectedAnnouncement);


  useEffect(() => {
    fetchData(1, 10);
  }, []);

  useEffect(() => {
    if (selectedAnnouncement === null) {
      setSelectedRowsArray([]);
    }
  }, [selectedAnnouncement]);

  const fetchData = async (page: number, pageSize: number) => {
    try {
      const data = await getAnnouncement(page, 9999999);
      setTableData(data.data.map((item, index) =>
        ({
          ...item,
          key: item.id,
          createdAt: dateFormatCustomize(item.createdAt, 'YY–MM-DD HH:mm:ss'),
          updatedAt: dateFormatCustomize(item.updatedAt, 'YY–MM-DD HH:mm:ss'),
        })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleModal = (isConfirm: boolean) => {
    setIsModalOpen(false);
  };

  const handleSelectedAnnouncement = () => {
    setSelectedAnnouncement(postList[0]);
    setIsModalOpen(false);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Announcement[]) => {
      setPostList(selectedRows);
      setSelectedRowsArray(selectedRowKeys);
    },
  };

  return (
    <Modal
      title='공지사항'
      open={isModalOpen}
      footer={null}
      width={1000}
      onCancel={() => handleModal(false)}
    >
      <div className="scrollable-table">
        <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='일치하는 내역이 없습니다'/>}>
          <Table
            columns={eventNoticeTableColumns.slice(1) as ColumnsType<Announcement>}
            dataSource={tableData}
            tableLayout='fixed'
            pagination={false}
            scroll={{ y: 'calc(100vh - 380px)' }}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: selectedRowsArray,
              ...rowSelection,
            }}
          />
        </ConfigProvider>
      </div>
      <Flex justify='end' style={{ width: '100%', marginTop: 10 }}>
        <Button disabled={selectedRowsArray.length === 0} type='primary' onClick={() => {
          handleSelectedAnnouncement();
        }}>
          적용하기
        </Button>
      </Flex>

    </Modal>
  );
};

export default PostListModal;
