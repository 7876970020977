import { del, get, post, put } from './index';
import { Announcement, AnnouncementResult } from '../types';

export async function createAnnouncement(title: string, content: string) {
  let url = '/admin/announcement';
  const data = { title, content };
  return await post(url, data);
}

export async function getAnnouncement(page: number, pageSize: number) {
  return await get<AnnouncementResult>(`/admin/announcement?page=${page}&pageSize=${pageSize}`);
}

export async function getAnnouncementById(id?: string) {
  return await get<Announcement>(`/admin/announcement/${id}`);
}

export async function editAnnouncement(id: string, title: string, content: string) {
  const data = { title, content };
  return await put(`/admin/announcement/${id}`, data);
}

export async function deleteAnnouncement(id: string | null) {
  return await del(`/admin/announcement/${id}`);
}


