import { FC, useEffect, useRef } from 'react';
import { Col, Flex, message, Row, Tabs, TabsProps, Typography } from 'antd';

import MainLayout from '../layout';
import SideBar from '../components/SideBar';
import { COLOR_333, COLOR_WHITE, FONT_SIZES, FONT_WEIGHTS, PADDING_SIZES } from '../constant';
import PushMessageForm from '../forms/PushMessageForm';
import ConnectionPostForm from '../forms/ConnectionPostForm';
import TableComponent from '../components/TableComponent';
import { shippingDetailTableColumns } from '../components/TableComponent/tableColumns';
import { shippingDetailTableData } from '../components/TableComponent/tableData';
import ListPushAnnouncement from './ListPushAnnouncement';
import useNotificationStore, { MESSAGE_TYPE } from '../store/notificationState';

interface IProps {}

const PushMessage: FC<IProps> = () => {
  const { isSuccess } = useNotificationStore();
  const isFirstRun = useRef(true);
  const setSuccess = useNotificationStore((state) => state.setSuccess);
  const [messageApi, contextHolder] = message.useMessage();


  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '발송하기',
      children: (
        <>
          <ConnectionPostForm />
          <PushMessageForm />
        </>
      ),
    },
    {
      key: '2',
      label: '발송내역',
      children: <ListPushAnnouncement />,
    },
  ];

  useEffect(() => {
    if (!isFirstRun.current) {
      if (isSuccess.value) {
        switch (isSuccess.type) {
          case MESSAGE_TYPE.FORGOT_ANNOUNCEMENT_ID:
            messageApi.error('연결 게시물을 반드시 선택 해주세요');
            break;
        }
      }
      setSuccess(false);

    } else {
      isFirstRun.current = false;
    }
  }, [isSuccess.value, isSuccess.type]);

  return (
    <MainLayout>
      {contextHolder}
      <Row style={{ minHeight: '100vh' }}>
        <SideBar />
        <Col span={20} style={{ backgroundColor: COLOR_WHITE, padding: PADDING_SIZES.lg }}>
          <Flex vertical gap={20}>
            <Typography.Text style={{ color: COLOR_333, fontSize: FONT_SIZES.huge, fontWeight: FONT_WEIGHTS.semibold }}>
              푸시메시지
            </Typography.Text>
            <Tabs type='card' items={items} size='large' />
          </Flex>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default PushMessage;
