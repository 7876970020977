import create from 'zustand';

export enum MESSAGE_TYPE {
  CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT',
  EDIT_ANNOUNCEMENT = 'EDIT_ANNOUNCEMENT',
  REMOVE_ANNOUNCEMENT = 'REMOVE_ANNOUNCEMENT',
  CREATE_PUSH_ANNOUNCEMENT = 'CREATE_PUSH_ANNOUNCEMENT',
  FORGOT_ANNOUNCEMENT_ID = "FORGOT_ANNOUNCEMENT_ID"
}

interface NotificationState {
  isSuccess: {
    value: boolean;
    type: MESSAGE_TYPE | null;
  };
  setSuccess: (value: boolean, type?: MESSAGE_TYPE | null) => void;
}

const useNotificationStore = create<NotificationState>((set) => ({
  isSuccess: {
    value: false,
    type: null,
  },
  setSuccess: (value, type) => {
    return set((state) => ({
      isSuccess: {
        ...state.isSuccess,
        value,
        type: type !== undefined ? type : state.isSuccess.type,
      },
    }));
  },
}));

export default useNotificationStore;
