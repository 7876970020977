import { FC, useEffect, useRef, useState } from 'react';
import { Button, Col, Flex, message, Row, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../layout';
import SideBar from '../components/SideBar';
import TableComponent from '../components/TableComponent';
import { eventNoticeTableColumns } from '../components/TableComponent/tableColumns';
import { COLOR_333, COLOR_WHITE, FONT_SIZES, FONT_WEIGHTS, PADDING_SIZES } from '../constant';
import { dateFormatCustomize, handleNavigate } from '../utils';
import { getAnnouncement } from '../api/announcement';
import { Announcement } from '../types';
import useNotificationStore, { MESSAGE_TYPE } from '../store/notificationState';

interface IProps {}

const EventNotice: FC<IProps> = () => {
  const [tableData, setTableData] = useState<Announcement[]>([]);
  const { isSuccess } = useNotificationStore();
  const setSuccess = useNotificationStore((state) => state.setSuccess);
  const [messageApi, contextHolder] = message.useMessage();
  const isFirstRun = useRef(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  let navigate = useNavigate();


  useEffect(() => {
    fetchData(page, pageSize);
  }, []);


  useEffect(() => {
    if (!isFirstRun.current) {
      if (isSuccess.value) {
        switch (isSuccess.type) {
          case MESSAGE_TYPE.CREATE_ANNOUNCEMENT:
            messageApi.success('성공적으로 등록되었습니다!');
            break;
          case MESSAGE_TYPE.EDIT_ANNOUNCEMENT:
            messageApi.success('성공적으로 등록되었습니다!');
            break;
          case MESSAGE_TYPE.REMOVE_ANNOUNCEMENT:
            messageApi.success('삭제 완료');
            break;
          default:
            break;
        }
      }
      setSuccess(false);

    } else {
      isFirstRun.current = false;
    }
  }, [isSuccess.value, isSuccess.type]);

  const fetchData = async (page: number, pageSize: number) => {
    try {
      const data = await getAnnouncement(page, pageSize);
      setTotal(data.total);
      setTableData(data.data.map((item, index) =>
        ({
          ...item,
          key: item.id,
          no: data.data.length - index,
          createdAt: dateFormatCustomize(item.createdAt, 'YY–MM-DD HH:mm:ss'),
          updatedAt: dateFormatCustomize(item.updatedAt, 'YY–MM-DD HH:mm:ss')
        })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <MainLayout>
      {contextHolder}
      <Row style={{ minHeight: '100vh' }}>
        <SideBar />
        <Col span={20} style={{ backgroundColor: COLOR_WHITE, padding: PADDING_SIZES.lg }}>
          <Flex vertical gap={20}>
            <Typography.Text style={{ color: COLOR_333, fontSize: FONT_SIZES.huge, fontWeight: FONT_WEIGHTS.semibold }}>
              공지 | 이벤트
            </Typography.Text>
            <Flex justify='end'>
              <Button
                type='primary'
                htmlType='button'
                size='large'
                style={{ paddingLeft: PADDING_SIZES.xl, paddingRight: PADDING_SIZES.xl }}
                onClick={() => handleNavigate(navigate, '/notice-event-detail')}
              >
                작성하기
              </Button>
            </Flex>
            <TableComponent tableColumns={eventNoticeTableColumns} tableData={tableData} noDataContent='' total={total} page={fetchData}/>
          </Flex>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default EventNotice;
