import create from 'zustand';
import { Announcement } from '../types';

interface AnnouncementStore {
  selectedAnnouncement: Announcement | null;
  setSelectedAnnouncement: (announcement: Announcement | null) => void;
  resetSelectedAnnouncement: () => void;
}

const useAnnouncementStore = create<AnnouncementStore>((set) => ({
  selectedAnnouncement: null,
  setSelectedAnnouncement: (announcement) => set({ selectedAnnouncement: announcement }),
  resetSelectedAnnouncement: () => set({ selectedAnnouncement: null }),
}));

export default useAnnouncementStore;
